import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  Drawer,
  MenuItem,
  Link
} from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
//import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext  } from "react";
//import { FcGoogle } from 'react-icons/fc';
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from '@mui/material/IconButton';
import { GoogleLogin, GoogleLogout  } from 'react-google-login';
import { gapi } from 'gapi-script';
//import jwt_decode from "jwt-decode";

import { useGoogleLogin } from '@react-oauth/google';
import { refreshTokenSetup } from "../refreshToken";
import { Context } from '../context';
import Subscriptions from "../subscriptions";



const useStyles = makeStyles(() => ({
  
  header: {
    /*backgroundColor: "#400CCC",*/
    paddingRight: "79px",
    paddingLeft: "118px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

const headersData = [
  {
    label: "Search",
    href: "/productsearch",
  },
  {
    label: "Subscriptions",
    href: "/subscriptions",
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Sign In",
    href: "",
  }
];

export default function Header() {
  //const { emailId, setEmailId } = useContext(Context);
  const [emailId, setEmailId ] = useState([]);//string

  const [ profile, setProfile ] = useState({});//object
  const clientId = `${process.env.REACT_APP_GOOGLE_CLIENTID}`;

  const onSuccess = (res) => {
    console.log("on success method called");
    setProfile(res.profileObj);
    //setEmailId(res.profileObj.email);
    if(res.profileObj) {
      localStorage.setItem("emailId", res.profileObj.email);
      /*window.dispatchEvent(new Event("storage"));*/
    }
    refreshTokenSetup(res);
    

  };

  const onFailure = (err) => {
      console.log('failed', err);
  };

  const logOut = () => {
      setProfile(null);
      localStorage.setItem("emailId", "");
      localStorage.setItem("authToken", "");
      window.location.reload();
  };

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false
  });

  const { mobileView, drawerOpen  } = state;


  useEffect(() => {

    const initClient = () => {
        gapi.client.init({
        clientId: `${process.env.REACT_APP_GOOGLE_CLIENTID}`,
        //fetch_basic_profile: true
        scope: 'profile email'
      });   
    };
    gapi.load('client:auth2', initClient);
    console.log("Environment(node_env, depends on command used to start the app): " + `${process.env.NODE_ENV}`);
    console.log("Environment: " + `${process.env.REACT_APP_ENVIRONMENT}`);

    console.log("Profile in Header useEffect: " + profile);

    console.log("Email in Header useEffect: " + profile.email);
    //if(profile) {
      //localStorage.setItem("emailId", profile.email);
    //}
    

    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
    //                window.location.reload();
  }, []);

  const { header, logo, menuButton, drawerContainer } = useStyles();

  const displayMobile = () => {
    const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));

    const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));

    const getDrawerChoices = () => {
      return headersData.map(({ label, href }) => {
        if (label !== "Sign In") {
          return (
            <Link 
              {...{
                component: RouterLink,
                to: href,
                color: "inherit",
                style: { textDecoration: "none" },
                key: label,
              }}
            >
              <MenuItem key={label}>{label}</MenuItem>
            </Link>
          ); 
        } else {
          return (getGoogleLoginButtonsMobile());
        }
      });
      //return getMenuButtons();
    };

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
          <div className={drawerContainer}>{getDrawerChoices()}</div>
          
        </Drawer>
        <div>{siteLogo}</div>
        
      </Toolbar>
    );
  };

  const displayDesktop = () => {
   // console.log("profile email: " + profile.email);
    return <Toolbar>
              {siteLogo}       
              <div>{getMenuButtons()}</div>
            </Toolbar>;
      };

  const siteLogo = (
    <Typography variant="h6" component="h1" className={logo}>
      
      <div style={{marginRight:"100px"}}>
          <img style={{width:75,height:65}} src="../../images/logo-color.png" />
      </div>
    </Typography>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      if (label !== "Sign In") {
        return (
          <Button 
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: RouterLink,
              className: menuButton
            }}
          >
            {label}
          </Button>
        );
      } else {
        return (getGoogleLoginButtonsDesktop());
      }
    });

    
  };

  const getGoogleLoginButtonsDesktop = () => {
    if(profile)
      console.log("profile is:" + profile.email);

    if(profile && profile !== undefined && profile.email !== undefined) {  
      return (      
          <div style={{display:'inline'}}>
            {/*<img src={profile.imageUrl} alt="user image" widht="40" height="40"/>*/}
            
            {/*{profile.email}*/}
            <GoogleLogout clientId={clientId} buttonText="Log out" onLogoutSuccess={logOut} 
              render={renderProps => (
                <Button onClick={renderProps.onClick} disabled={renderProps.disabled}
                    {...{
                      key: "signout",
                      color: "inherit",
                      to: "",
                      component: RouterLink,
                      className: menuButton,
                    }}
                  >
                    sign out
                </Button>
                )}
              />
              <div style={{display: 'inline',marginLeft:'38px'}}>Welcome {profile.name} !</div>
          </div>
        );
      } else {
      return (<GoogleLogin
        clientId={clientId}
        buttonText="Sign in with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        isSignedIn={true}
        style={{marginLeft: '100px'}}
        render={renderProps => (
        <Button onClick={renderProps.onClick} disabled={renderProps.disabled}
            {...{
              key: "signin",
              color: "inherit",
              to: "",
              component: RouterLink,
              className: menuButton,
            }}
          >
            sign in
          </Button>
        )}
      />);
      
      } 
  };

  const getGoogleLoginButtonsMobile = () => {
    console.log("profile is:" + profile)
    if(profile && profile !== undefined && profile.email !== undefined) {  
      return (      
          <div style={{display:'inline'}}>
            {/*<img src={profile.imageUrl} alt="user image" widht="40" height="40"/>*/}
            
            {/*{profile.email}*/}
            <GoogleLogout clientId={clientId} buttonText="Log out" onLogoutSuccess={logOut} 
              render={renderProps => (
                <Link onClick={renderProps.onClick} disabled={renderProps.disabled}
                    {...{
                      key: "signout",
                      color: "inherit",
                      to: "",
                      component: RouterLink,
                      style: { textDecoration: "none" },
                    }}
                  >
                    <MenuItem key={'Sign out'}>Sign Out</MenuItem>
                </Link>
                )}
              />
              <div style={{marginLeft:'18px'}}>Welcome {profile.name} !</div>
          </div>
        );
      } else {
      return (<GoogleLogin
        clientId={clientId}
        buttonText="Sign in with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        isSignedIn={true}
        style={{marginLeft: '100px'}}
        render={renderProps => (
        <Link onClick={renderProps.onClick} disabled={renderProps.disabled}
            {...{
              key: "signin",
              color: "inherit",
              to: "",
              component: RouterLink,
              style: { textDecoration: "none" },
            }}
          >
           <MenuItem key={'Sign In'}>Sign In</MenuItem>
          </Link>
        )}
      />);
      
      } 
  };

  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}