import Marquee from "react-fast-marquee";



export default function MarqueeComponent() {
    return (
        <Marquee speed="50">
            <div style={{marginRight:"100px"}}>
                <a href="https://www.canadiantire.ca" target="_blank"><img style={{width:75,height:75}} src="../../images/carousel/Canadian_Tire.png" /></a>
            </div>
            <div style={{marginRight:"90px"}}>
                <a href="https://www.homedepot.ca" target="_blank"><img style={{width:130,height:75}} src="../../images/carousel/Home_Depot.png" /></a>
            </div>
            <div>
                {/*<img style={{width:185,height:50}} src="../../images/carousel/Walmart.png" />*/}
            </div>
            <div style={{marginRight:"90px"}}>
                <a href="https://www.marks.com" target="_blank"><img style={{width:80,height:75}} src="../../images/carousel/marks.png" /></a>
            </div>
            <div >
                {/* <a href="https://www.lowes.ca" target="_blank"><img style={{width:100,height:85}} src="../../images/carousel/Lowes.png" /></a> */ }
            </div>
            <div >
                {/* <a href="https://www.princessauto.com/" target="_blank"><img style={{width:150,height:85}} src="../../images/carousel/Princess_Auto_Logo.png" /></a> */}
            </div>
            <div style={{marginRight:"90px"}}>
                <a href="https://www.bestbuy.com/" target="_blank"><img style={{width:150,height:85}} src="../../images/carousel/BestBuy.png" /></a>
            </div>
        </Marquee>
    );

}