import React from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
//import { makeStyles } from "@material-ui/core/styles";
//import { styled } from "@mui/system";
//import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
//import Button from '@mui/material/Button';
//import { Button } from "@material-ui/core";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material/Button';
import { Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Linechart from './shared/linechart';
import { getRetailerLogoForProduct } from "./shared/utils";
import { faker } from '@faker-js/faker';

 

  const useStyles = makeStyles(() => ({
    menuButton: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 700,
      size: "18px",
      marginLeft: "38px",
    },
  }));



function ProductSearch(props) {
  const [product, setProduct] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [subscriptionMessage, setSubscriptionMessage] = React.useState(null);
  const [subscriptionError, setSubscriptionError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  
  const errorDiv = error 
                    ? <div style={{paddingTop:'10px'}}>
                        <i style={{color:'red'}}>
                            {error}
                        </i>
                        </div> 
                    : '';
  const subscriptionMsgDiv = subscriptionMessage ? <div style={{paddingTop:'10px'}}>
                                    <i style={{color:'green'}}>
                                        {subscriptionMessage}
                                    </i>
                                    </div> 
                                    : '';
    const subscriptionErrorDiv = subscriptionError ? <div style={{paddingTop:'10px'}}>
                                    <i style={{color:'red'}}>
                                        {subscriptionError}
                                    </i>
                                    </div> 
                                    : '';                                

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({defaultValues: {url:""}});

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 }
  } = useForm({defaultValues: {desiredPrice:""}});

  const inputProps = {
    step: 300,
    size: 100,
  };

  /***Chart config starts */
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  const [priceHistory, setPriceHistory] = React.useState([]);
  const [chartData, setChartData] = React.useState({
    /*labels: priceHistory.map((priceHistoryObj) => priceHistoryObj.creationDate),*/
    datasets: [   
      {
        label: 'price',
        data: [],
        /*data: priceHistory.map((priceHistoryObj) => priceHistoryObj.price),*/
       /*data: [{
            x: '2021-08-08T13:12:23',
            y: 3
          },
          {
            x: '2021-08-08T13:12:45',
            y: 5
          },
          {
            x: '2021-08-08T13:12:46',
            y: 6
          },
          {
            x: '2021-08-08T13:13:11',
            y: 3
          },
          {
            x: '2021-08-08T13:14:23',
            y: 9
          },
          {
            x: '2021-08-08T13:16:45',
            y: 1
          }
        ],*/
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });
  /***Chart config Ends */
  

  const { menuButton } = useStyles();

  const onProdSearchSubmit = (formData) => {
    setError(null);
    setSubscriptionError(null);
    setSubscriptionMessage(null);
    setLoading(true);
    console.log(formData);
    axios
      .get(`${process.env.REACT_APP_PRODUCTFETCHER_URL}/api/v1/products/urlsearch`, {
          params: {
              productUrl: formData.url,
              skipDB: true
          },
          headers: {
            'authorization' : `${localStorage.getItem('authToken')}`
            //'Access-Control-Allow-Origin' : `${process.env.REACT_APP_HOST_URL}`
          }
          /*,
          headers: {
              'Access-Control-Allow-Origin' : `${process.env.REACT_APP_HOST_URL}`

          }*/
      })
      .then((response) => {
        console.log("prodSearchResponse: " , response.data); 
        setProduct(response.data);
        const priceHis = createChartData(response.data.priceHistory);
        setPriceHistory(priceHis);
        setChartData({datasets: [   
            {
              label: 'price',
              data: [priceHistory.map((priceHistoryObj) => { return {x: priceHistoryObj.x, y:priceHistoryObj.y}})],
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ]});
        console.log("priceHistory: " , priceHistory);
        console.log("chart data", chartData);
        setLoading(false);
    }).catch(error => {
      setProduct([]);
      if(error.response.status === 500) {
        console.log("Error: "+error.message); 
        setError("Please try after sometime, if continues, report the issue");
        setLoading(false);
      } else {
        console.log("Error: "+ error.response.data.message); 
        setError(error.response.data.message);
        setLoading(false);
      }
    });

  };

  function createChartData(priceHistory) {
    const newPriceHistoryObj = priceHistory.map(
        ({ price, creationDate, onlineStock }) => ({
          x: price,
          y: creationDate.substring(0,10)
          //onlineStock: onlineStock
        })
      );

      return newPriceHistoryObj;
  }

  const onSubscribeSubmit = (formData) => {
    console.log(formData);
    setSubscriptionError(null);
    if(formData.desiredPrice === undefined || formData.desiredPrice <= 0) {
      setSubscriptionError("Subscription price cant be null/0 or -ve");
      return;
    }
    axios
      .post(`${process.env.REACT_APP_PRODUCTFETCHER_URL}/api/v1/products/subscribe`, {
            prodId: product.prodId,
            retailerName: product.retailerName,
            retailerCountry: product.retailerCountry,
            enable: true,
            desiredProductPrice: formData.desiredPrice,
            priceAtSubscription: product.currentPrice,
            skuId: product.sku,
            email: localStorage.getItem("emailId"),//props.email
        },
        {headers: {
          'authorization' : `${localStorage.getItem('authToken')}`
//'Access-Control-Allow-Origin' : `${process.env.REACT_APP_HOST_URL}`
        }})
      .then((response) => {
        console.log(response.data); 
        setSubscriptionMessage(response.data)
    }).catch(error => { 
      if(error.response.status === 500) {
        setSubscriptionError("Please Try after sometime");
      } else if (error.response.status === 403) {
        setSubscriptionError("Please login to subscribe");
      } else {
        console.log("Error: "+ error.response.data); 
        if(error.response.data.message !== undefined) {
          setSubscriptionError(error.response.data.message);
        }
      }
      
    });

  };

  return (
    
    <div >
        <label style={{color:'#3f51b5',fontSize:'30px',fontFamily: "Open Sans, sans-serif",fontWeight: 700}}>Product Search</label>
        <div>
            <form key={"productUrlSearchForm"} onSubmit={handleSubmit(onProdSearchSubmit)} >
                <div >
                    
                    {/*<label>Url </label>
                    <input type="text" name="url" {...register("url", {required: true})} size="80"/>
                    {errors.url && errors.url.type === "required" && (
                        <p className="errorMsg">Url is required.</p>
                    )}*/}
                    <TextField id="url" name="url" type="text" inputProps={inputProps} label="Enter URL of Product" variant="standard" {...register("url", {required: true})}
                     />
                    {errors.url && errors.url.type === "required" && (
                        <p className="errorMsg">*Url is required.</p>
                    )}
                    {errorDiv}
                </div>
              
                    <div className="form-control" style={{marginTop:'20px', marginBottom:'40px'}}>
                        
                        
                        <LoadingButton
                            type="submit"
                            size="small"                            
                            loading={loading}
                            //loadingPosition="end"
                            variant="contained"
                            >
                            Search
                            </LoadingButton>
                    </div>
              
            </form>
        </div>
        {!(Object.keys(product).length === 0) ?
            <div>
                <div className="subscriptions">
                    <table >
                        <tbody>
                            <tr style={{"backgroundColor": "#3f51b5", "color":"white"}}>
                                <td >Retailer</td>
                                <td >Product Image</td>
                                <td >Product</td>
                                  {product.regularPrice === undefined ?<></> : <td >Regular price</td>}
                                <td >Current Price</td>
                                
                            </tr>          
                            <tr key={product.prodId}>
                                <td>
                                  {getRetailerLogoForProduct(product.retailerName)}
                                </td> 
                                <td>
                                    <img src={product.productImg} alt="" style={{"align":"left", width:75,height:75}}/>
                                </td> 
                                <td>
                                    <a href={product.productUrl} rel="noreferrer" style={{"color": "black"}} target="_blank">{product.productDescription}</a>
                                </td>
                                {product.regularPrice === undefined ?<></> :
                                  <td style={{"color": "black"}}>
                                      {product.regularPrice}
                                  </td>
                                }
                                <td>
                                    {product.currentPrice}
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                   
                </div>
                <div className="lineChart" style={{position: 'absolute', left: '50%'}}>
                    {/*<Linechart chartData={chartData} />*/}
                </div>
                <div>
                    <form key={"subscriptionForm"} onSubmit={handleSubmit2(onSubscribeSubmit)} >
                        <div className="form-control" style={{marginTop:'20px'}}>
                            
                            {/*<input type="number" name="desiredPrice" {...register("desiredPrice", {required: true})} size="30"/>*/}
                            <TextField id="desiredPrice" name="desiredPrice" type="text" inputProps={{ inputMode: 'numeric', pattern: '-?[0-9]*(\.[0-9]+)?', size:30 }}
                             label="Enter desired price for alerts" 
                             variant="standard"  {...register2("desiredPrice", {required: true})} />
                            {errors2.desiredPrice && errors2.desiredPrice.type === "required" && (
                                <p className="errorMsg">*Price is required to subscribe.</p>
                            )}
                            {subscriptionMsgDiv}
                            {subscriptionErrorDiv}
                        </div>
                        
                        <div className="form-control" style={{marginTop:'20px', marginBottom:'40px'}}>
                            {/*<button type="submit">Search</button>*/}
                            <Button type="submit" > Subscribe </Button>
                        </div>
                        
                    </form>
                </div>
            </div>
        :""} {/* if else doesnt work here in JSX/render function*/}
    </div>
  );
}

export default ProductSearch; 