import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';


ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

function Linechart({ chartData }) {
  return <div><Line options={options} data={chartData} /></div>;
}

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      display: false
    },
    title: {
      display: true,
      text: 'Price History',
    },
  },
  scales: {
    x: {
      type: "time",
      distribution: 'linear',
      time: {
        unit: "day",
        //unitStepSize: 1000,
        displayFormats: {
          minute: 'MMM dd',
          hour: 'MMM dd',
          day: 'MMM dd',
          week: 'MMM dd',
          month: 'MMM dd',
          quarter: 'MMM dd',
          year: 'MMM dd',
        }
        /*time: {
          displayFormats: {
            millisecond: 'MMM do hh:mm',
            second: 'MMM do hh:mm',
            minute: 'MMM do hh:mm',
            hour: 'MMM do hh:mm',
            day: 'MMM do hh:mm',
            week: 'MMM do hh:mm',
            month: 'MMM do hh:mm',
            quarter: 'MMM do hh:mm',
            year: 'MMM do hh:mm',
          },*/
      },
    },
    y: {
      beginAtZero: true
    }

  }
};


export default Linechart;