export const refreshTokenSetup = (res) => {
    // Timing to renew access token
    //let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
  
    let refreshTiming = res.tokenObj.expires_in;// * 1000;
    console.log("Token expires in : " + refreshTiming + " millseconds");

    const refreshToken = async () => {
      const newAuthRes = await res.reloadAuthResponse();
      //refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
      refreshTiming = newAuthRes.expires_in;
      console.log("Token expires in : " + refreshTiming + " millseconds");
      console.log('newAuthRes:', newAuthRes);
      // saveUserToken(newAuthRes.access_token);  <-- save new token
      localStorage.setItem('authToken', newAuthRes.id_token);
      localStorage.setItem("authTokenLastRefreshed", Date().toLocaleString());
  
      // Setup the other timer after the first one
      setTimeout(refreshToken, 3595000); // this method is to delay the execution in milliseconds
    };

    localStorage.setItem('authToken', res.tokenObj.id_token);
    localStorage.setItem("authTokenLastRefreshed", Date().toLocaleString());
    // Setup first refresh timer
    setTimeout(refreshToken, 3595000);
  };