export const getRetailerLogoForProduct = (retailerName) => {

    if(retailerName === 'CanadianTire') {
        const logoPath = `${process.env.REACT_APP_CANADIAN_TIRE_LOGO}`;
        return <a href="https://www.canadiantire.ca" target="_blank"><img style={{width:65,height:55}} src={logoPath} title='Canadian Tire'/></a>;
    }
    if(retailerName === 'Walmart') {
        return <a href="https://www.walmart.ca" target="_blank"><img style={{width:100,height:30}} src={`${process.env.REACT_APP_WALMART_LOGO}`} title='Walmart'/></a>;
    }
    if(retailerName === 'HomeDepot') {
        return <a href="https://www.homedepot.ca" target="_blank"><img style={{width:130,height:75}} src={`${process.env.REACT_APP_HOME_DEPOT_LOGO}`} title='Home Depot'/></a>;
    }
    if(retailerName === 'Marks') {
        return <a href="https://www.marks.com" target="_blank"><img style={{width:80,height:75}} src={`${process.env.REACT_APP_MARKS_LOGO}`} title='Marks'/></a>;
    }
    if(retailerName === 'Lowes') {
        return <a href="https://www.lowes.ca" target="_blank"><img style={{width:80,height:75}} src={`${process.env.REACT_APP_LOWES_LOGO}`} title='Lowes'/></a>;
    }
    if(retailerName === 'BestBuy') {
        return <a href="https://www.BestBuy.ca" target="_blank"><img style={{width:110,height:75}} src={`${process.env.REACT_APP_BESTBUY_LOGO}`} title='Lowes'/></a>;
    }
  };